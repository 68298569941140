import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { graphql } from "gatsby"
import CookieConsent from 'react-cookie-consent';


const Layout = props => {
  const { title, children } = props
  const [toggleNav, setToggleNav] = React.useState(false)
  const { pathname } = useLocation()

  return (
    <div className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}>
      <header className="site-head">
        <div className="site-head-container">
          <a
            className="nav-burger"
            href="#"
            onClick={() => setToggleNav(!toggleNav)}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </a>
          <nav id="swup" className="site-head-left">
            <ul className="nav" role="menu">
              <li
                className={`nav-about ${["/about", "/"].includes(pathname) ? "nav-current" : ""
                  }`}
                role="menuitem"
              >
                <Link to={`/about`}>O mnie</Link>
              </li>
              <li
                className={`nav-offer ${pathname === "/offer" ? "nav-current" : ""
                  }`}
                role="menuitem"
              >
                <Link to={`/offer`}>Oferta</Link>
              </li>
              <li
                className={`nav-remuneration ${pathname === "/remuneration" ? "nav-current" : ""
                  }`}
                role="menuitem"
              >
                <Link to={`/remuneration`}>Wynagrodzenie</Link>
              </li>
              <li
                className={`nav-contact ${pathname === "/contact" ? "nav-current" : ""
                  }`}
                role="menuitem"
              >
                <Link to={`/contact`}>Kontakt</Link>
              </li>
            </ul>
          </nav>
          <div className="site-head-center">
            <Link className="site-head-logo" to={`/`}>
              <small>Kancelaria Adwokacka Adwokat</small>
              <big>Agnieszka Borkowska</big>
            </Link>
          </div>
          <div className="site-head-right">
            <ul className="social-links">
              <li
                className={`nav-privacy ${
                  pathname === "/privacy" ? "nav-current" : ""
                }`}
                role="menuitem"
              >
                <Link to={`/privacy`}>Polityka prywatności</Link>
              </li>
              {/* <li
                className={`nav-privacy-policy ${
                  pathname === "/privacy-policy" ? "nav-current" : ""
                }`}
                role="menuitem"
              >
                <Link to={`/privacy-policy`}>Polityka prywatności</Link>
              </li> 
              <li className="adw-logo">
                <StaticQuery
                  query={adwQuery}
                  render={data => (
                    <Image
                      fixed={data.adwlogo.childImageSharp.fixed}
                      alt="test"
                      imgStyle={{
                        borderRadius: `50%`,
                      }}
                    />
                  )}
                />
              </li>*/}
            </ul>
          </div>
        </div>
      </header>
      <main id="site-main" className="site-main">
        <div id="swup" className="transition-fade">
          {children}
        </div>
      </main>
      <div>
        
        <CookieConsent
          location="bottom"
          buttonText="Zaakceptuj"
          declineButtonText="Odrzuć"
          enableDeclineButton
          cookieName="gatsby-gdpr-google-analytics"

          disableStyles={true}
          flipButtons={true}
          >
          <span id="cookie-info">
            Nasza strona internetowa używa plików cookies w celach statystycznych.
          </span>
        </CookieConsent>
      </div>
      <footer className="site-foot">
        &copy; {new Date().getFullYear()} <Link to={`/`}>{title}</Link>
      </footer>
    </div >
  )
}

export default Layout

const adwQuery = graphql`
  query AdwQuery {
    adwlogo: file(relativePath: { eq: "adwokatura.png" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`
